import { Directive, Input, ElementRef } from '@angular/core';

@Directive({   
    selector: '[background-color]'
})

export class BackgroundColorDirective {
    @Input('dynamicColor') dynamicColor: string;

    constructor(private elRef: ElementRef) {  
        this.changeBackgroundColor(this.dynamicColor);
    }

    private changeBackgroundColor(color: string) {  
        this.elRef.nativeElement.style.backgroundColor = color;
    }
}