import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MixpanelService } from './services/mixpanel.service';
import { environment } from './../environments/environment';


declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Eduauraa: A Premier Digital Learning Platform';
  prevUrl: string = 'N/A';
  pageViewObj: any;

  constructor(private titleService: Title, private meta: Meta, private router: Router,
    private _mixpanelService: MixpanelService) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    if(localStorage.getItem('sessionStart')) {
      localStorage.removeItem('sessionStart');
    }
    var date = new Date();
    const sessionTime = parseInt((date.valueOf() / 1000).toString());
    localStorage.setItem('sessionStart', sessionTime.toString());
    this._mixpanelService.init(environment.eduauraaMixpanelKey);
    this._mixpanelService.identify();
    this._mixpanelService.register({
      "Unique ID": "N/A",
      "Partner Name": "Eduauraa",
      "Session ID": localStorage.getItem('sessionStart'),
      "Platform Name": environment.platformName,
    });

    this.pageViewObj = {
      "Page Name": "subsciption"
    }

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((x: any) => {
        let tempArr = x.url.split('/');
        gtag('event', 'page_view', {
          page_path: x.urlAfterRedirects
       })
        // console.log("tempArr[tempArr.length - 1] : "+tempArr[tempArr.length - 1]);
        this._mixpanelService.track('Page View', {
          "Page Name": tempArr[tempArr.length - 1].trim() == ''?'Login':tempArr[tempArr.length - 1].trim(),
          "User ID": localStorage.getItem('username')?localStorage.getItem('username'):'N/A',
          "User Type": localStorage.getItem('vendortage')?'Premium': (localStorage.getItem('username')?'Registered':'Guest'),
          "Platform Name": environment.platformName,
          "Session ID": localStorage.getItem('sessionStart'),
          "Platform Language": 'N/A',
          "Source Page": this.prevUrl
        });
        this._mixpanelService.prevUrl = this.prevUrl;
        this.prevUrl = tempArr[tempArr.length - 1].trim() == ''?'Login':tempArr[tempArr.length - 1].trim();
        
      });

    //set metadata tag
    this.meta.addTags([
      { name: 'keywords', content: 'eduauraa, eduauraa web app, lms' },
      { name: 'description', content: 'Eduauraa: A Premier Digital Learning Platform' },
      { name: 'author', content: 'Eduauraa Technology Pvt Ltd.' }
    ]);
  }

}
