import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthguardGuard } from './Auth/authguard.guard';
import { HttpClientModule } from '@angular/common/http';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', loadChildren: './components/login/login.module#LoginModule' },
  { path: 'signup', loadChildren: './signup/signup/signup.module#SignupModule', pathMatch: 'full' },
  { path: 'auth', loadChildren: './layout/layout/layout.module#LayoutModule', canActivate: [AuthguardGuard] },
  { path: 'zee5', loadChildren: './onboarding/onboarding.module#OnboardingModule' },
  { path: 'subscription', loadChildren: './subscription/subscription.module#SubscriptionModule' },
  // { path: 'updateProfile', loadChildren: './user-profile/profile-update/profile-update.module#profile-updateModule'}
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled', //scroll to the top while routing
      useHash: true
    }),
    HttpClientModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
