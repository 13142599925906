import { Directive } from '@angular/core';

@Directive({
  selector: '[appIframeTracker]'
})
export class IframeTrackerDirective {

  constructor() { }

}
