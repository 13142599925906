// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://lms.eduauraa.com/v1/prod/',
  //apiUrl: "http://localhost:4200/api/",
  // apiUrl: "https://staging.eduauraa.com/",
  eduauraaMixpanelKey: '7d0aae6f72a8cf0ec2b5e81bd9d98400',
  zee5MixpanelKey: '28ad1bd3f9fbcda7356c70835a0b4d96',
  platformName: 'Web Eduauraa'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
