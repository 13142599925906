import { Injectable } from '@angular/core';
import * as mixpanel_eduauraa from 'mixpanel-browser';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {
  pack: string = '';
  cost: string = '';
  prevUrl: string = '';

  constructor() { }

  init(userToken: string) {
    console.log("mixpanel Service call");
    mixpanel_eduauraa.init(userToken);
  }

  identify() {
    mixpanel_eduauraa.identify();
  }

  register(action: any = {}) {
    mixpanel_eduauraa.register(action);
  }

  people(action: any = {}) {
    mixpanel_eduauraa.people.set(action);
  }

  track(id: string, action = {}):void {
    mixpanel_eduauraa.track(id, action);
  }
}
