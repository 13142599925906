import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RoundPipe } from './pipes/round.pipe';
import { BackgroundColorDirective } from './directives/background-color.directive';
import { ToastrModule } from 'ngx-toastr';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NoRightClickDirective } from './directives/no-right-click.directive';
import { IframeTrackerDirective } from './directives/iframe-tracker.directive';

// import { HttpCacheService } from './services/cache.service';
// import { cacheInterceptor } from './services/cacheinterceptor';


@NgModule({
  declarations: [
    AppComponent,
    RoundPipe,
    BackgroundColorDirective,
    NoRightClickDirective,
    IframeTrackerDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    PdfViewerModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,

    })
  ],
  // providers: [
  //   {
  //     provide: HTTP_INTERCEPTORS,
  //     useClass: cacheInterceptor,
  //     multi: true
  //   },
  //   HttpCacheService
  // ],
  bootstrap: [AppComponent]
})
export class AppModule { }
